import React, { useState, useEffect } from "react"
import Icon from "../utils/Icon"
import { Figure } from "react-bootstrap"

const FigureBox = props => {
  const [data, setData] = useState({})

  useEffect(() => {
    setData(props.data)
  }, [props])

  return (
    <Figure className={props.className} onClick={props.onClick}>
      <Figure.Image
        alt="portfolio"
        src={
          !props.isDetails
            ? props.data.mainImage !== undefined &&
              props.data.mainImage.childImageSharp.fluid.originalImg
            : props.data.childImageSharp.fluid.originalImg
        }
      />
      {!props.isDeatils && (
        <Figure.Caption>
          <div className="title">
            <h3>
              {data.title}
              <small>{data.subtitle}</small>
            </h3>
            <Icon name="arrowcurve" size="4em" color="white" />
          </div>
        </Figure.Caption>
      )}
    </Figure>
  )
}

export default FigureBox
