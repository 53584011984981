import React from "react"
import { navigate } from "gatsby"
import SEO from "../components/seo"
import Title from "../components/title"
import Layout from "../components/layout"
import Buttons from "../components/button"
import FigureBox from "../components/figure"
import { useStaticQuery, graphql } from "gatsby"
import { Row, Col, Container } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      allJsonJson {
        nodes {
          data {
            id
            client
            description
            title
            subtitle
            mainImage {
              childImageSharp {
                fluid {
                  originalImg
                }
              }
            }
            detailImg {
              childImageSharp {
                fluid {
                  originalImg
                }
              }
            }
          }
        }
      }
    }
  `)

  const handleClick = items => navigate("/details/", { state: items })

  return (
    <Layout className="home">
      <SEO title="Home" />
      <Title
        title="Visual designer based in Dubai with a passion for creativity in design"
        description="Hi, I am jayadevan - visual designer with more than 8 years of experience in UI/UX, web design, graphic design and 2d animation."
      />
      <Container>
        <Row>
          {data !== undefined &&
            data.allJsonJson.nodes[0].data.map(items => {
              return (
                <Col md={6} key={items.id}>
                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOnce={true}
                    offset={110}
                  >
                    <FigureBox
                      className={`thumb ${items.id ? items.id : ""}`}
                      data={items}
                      onClick={() => handleClick(items)}
                    />
                  </ScrollAnimation>
                </Col>
              )
            })}
        </Row>
        <Buttons>More Works</Buttons>
      </Container>
    </Layout>
  )
}

export default IndexPage
