import React from "react"

const Buttons = props => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.behance.net/jaydevanimator"
      className={`btn btn-primary ${
        props.variant !== undefined ? props.variant : ""
      }`}
    >
      <span>{props.children}</span>
    </a>
  )
}

export default Buttons
